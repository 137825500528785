import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      prefectures: [],
      contractGroupList: [],
      legalPersonalityTypes: [],
      contractTypes: [],
      invoiceTypes: [],
      invoiceMethodTypeList: [],
      duplicatedNameInfoList: [], //重複する名称情報
    };
  },

  methods: {
    // 排出事業者契約詳細取得API  GENERATOR_CONTRACT_INFO + {id}
    // 編集用
    getGeneratorContractInfoApi(contractId) {
      return axios
        .get(API_URL.GENERATOR.CONTRACTOR_INFO + contractId)
        .then((res) => {
          const data = res.data;
          this.formEdit = {
            generateContractGroupId: data.generateContractGroupInfo ? data.generateContractGroupInfo.id : null,
            generateContractGroupInfo: {
              legalPersonalityTypeId: null,
              contractGroupName: "",
            },
            jwnetLineTypeId: data.generateContractInfo.jwnetLineTypeId,
            contractName: data.generateContractInfo.contractName,
            addressInfo: {
              zipCode: data.generateContractInfo.zipCode,
              prefecturesId: data.generateContractInfo.prefecturesId,
              prefecturesName: data.generateContractInfo.prefecturesName,
              address: data.generateContractInfo.address,
              buildingName: data.generateContractInfo.buildingName,
            },
            tel: data.generateContractInfo.tel,
            fax: data.generateContractInfo.fax,
            isHeadquarters: data.generateContractInfo.isHeadquarters,
            contractTypeId: data.generateContractInfo.contractTypeId,
            invoiceTypeId: data.generateContractInfo.invoiceTypeId,
            isSameAddress: data.generateContractInfo.isSameAddress,
            invoiceAddressInfo: {
              zipCode: data.generateContractInfo.invoiceZipCode ? data.generateContractInfo.invoiceZipCode : "",
              prefecturesId: data.generateContractInfo.invoicePrefecturesId ? data.generateContractInfo.invoicePrefecturesId : null,
              prefecturesName: data.generateContractInfo.invoicePrefecturesName ? data.generateContractInfo.invoicePrefecturesName : null,
              address: data.generateContractInfo.invoiceAddress ? data.generateContractInfo.invoiceAddress : "",
              buildingName: data.generateContractInfo.invoiceBuildingName ? data.generateContractInfo.invoiceBuildingName : "",
            },
            contractStaffDepartment: data.generateContractInfo.contractStaffDepartment,
            contractStaffName: data.generateContractInfo.contractStaffName,
            contractStaffMail: data.generateContractInfo.contractStaffMail,
            remarks: data.generateContractInfo.remarks,
            cbaContractStartDate: data.generateContractInfo.cbaContractStartDate,
            cbaContractEndDate: data.generateContractInfo.cbaContractEndDate,
            isAutoUpdate: data.generateContractInfo.isAutoUpdate,
            isDiscountCampaign: data.generateContractInfo.isDiscountCampaign,
            invoiceMethodTypeId: data.generateContractInfo.invoiceMethodTypeId,
            department: data.generateContractManagementMemberInfo.department,
            memberName: data.generateContractManagementMemberInfo.name,
            memberMail: data.generateContractManagementMemberInfo.mail,
            memberTel: data.generateContractManagementMemberInfo.tel,
            memberMobilePhone: data.generateContractManagementMemberInfo.mobilePhone,
            memberId: data.generateContractManagementMemberInfo.id,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 企業グループ選択肢取得API   GENERATOR.CONTRACT_GROUP_LIST
    getContractGroupListApi() {
      return axios
        .get(API_URL.GENERATOR.CONTRACT_GROUP_LIST)
        .then((res) => {
          this.contractGroupList = res.data.generateContractGroupList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 法人格選択肢取得API   DOMAIN.LEGAL_PERSONALITY_TYPE
    getLegalPersonalityTypesApi() {
      return axios
        .get(API_URL.DOMAIN.LEGAL_PERSONALITY_TYPE)
        .then((res) => {
          this.legalPersonalityTypes = res.data.legalPersonalityTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 契約プラン選択肢取得API   DOMAIN.CONTRACT_TYPE
    getContractTypesApi() {
      return axios
        .get(API_URL.DOMAIN.CONTRACT_TYPE)
        .then((res) => {
          this.contractTypes = res.data.contractTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 請求方法選択肢取得API
    getInvoiceMethodTypeListApi() {
      return axios
        .get(API_URL.DOMAIN.INVOICE_METHOD_TYPE)
        .then((res) => {
          this.invoiceMethodTypeList = res.data.invoiceMethodTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 請求タイミング選択肢取得API   DOMAIN.INVOICE_TYPE
    getInvoiceTypesApi() {
      return axios
        .get(API_URL.DOMAIN.INVOICE_TYPE)
        .then((res) => {
          this.invoiceTypes = res.data.invoiceTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 所属による名称が重複チェックAPI
    checkDuplicationName(duplicationNameCheckInfoList) {
      return axios
        .post(API_URL.DUPLICATION.NAME, {duplicationNameCheckInfoList : duplicationNameCheckInfoList})
        .then((res) => {
          this.duplicatedNameInfoList = res.data.duplicatedNameInfoList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
