export default {
  data() {
    return {
      selectedGroupOptVal: null, // 所属グループ選択結果
      selectedGroup: null, // 既存グループ選択時
    };
  },

  methods: {
    // 所属グループ変更時
    changeGroupHandler(form) {
      if (this.selectedGroupOptVal === "none") {
        // 処理なし（generateContractGroupInfoのプロパティは不要なので削除）
        form.generateContractGroupId = null;
        if (!this.isEdit) {
          form.generateContractGroupInfo = {};
        }
      } else if (this.selectedGroupOptVal === "create-new") {
        // 新規グループから作る （generateContractGroupInfoにプロパティ追加）
        if (!this.isEdit) {
          form.generateContractGroupId = null;
          form.generateContractGroupInfo = {
            ...form.generateContractGroupInfo,
            legalPersonalityTypeId: null,
            contractGroupName: "",
          };
        }
      } else if (this.selectedGroupOptVal === "select-group") {
        // 既存グループから選択（generateContractGroupInfoのプロパティは不要なので削除）
        if (!this.isEdit) {
          form.generateContractGroupId = null;
          form.generateContractGroupInfo = {};
        }
      }
    },

    // 既存グループ選択時
    changeSelectGroupHandler() {
      if (this.isEdit) {
        this.form.generateContractGroupInfo = {
          id: this.selectedGroup.id,
          name: "",
          legalPersonalityType: {
            id: null,
            name: "",
          },
        };
      } else {
        this.form.generateContractGroupId = this.selectedGroup.id;
        this.form.generateContractGroupInfo = {};
      }
    },
  },
};
