import axios from "axios";
import { EXTERNAL_API_URL } from "@/const";
import axiosJsonpAdapter from "axios-jsonp";

export default {
  data() {
    return {
      isAddressSelectModalShow: false,
      addressResults: [], // 住所検索モーダル内で使用
      selectedAddress: {}, // 【企業情報】住所検索結果（モーダル展開時はモーダル内選択結果）
      selectedInvoiceAddress: {}, // 【請求先】住所検索結果（モーダル展開時はモーダル内選択結果）
      isCompanyInfo: true, // も＾ダル展開時に企業情報か請求先かを判定
    };
  },

  methods: {
    /**
     * 住所検索押下
     * isCompanyInfo ? 企業情報の郵便番号 : 請求先の郵便番号
     */
    searchAddressHandler(isCompanyInfo, isEdit = false) {
      this.isCompanyInfo = isCompanyInfo;
      const url = isEdit
        ? this.isCompanyInfo ? `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.formEdit.addressInfo.zipCode}`
          : `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.formEdit.invoiceAddressInfo.zipCode}`
        : this.isCompanyInfo ? `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.form.addressInfo.zipCode}`
          : `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.form.invoiceAddressInfo.zipCode}`;
      axios
        .get(url, { adapter: axiosJsonpAdapter })
        .then((res) => {
          const results = res.data.results;

          if (results === null) {
            alert("該当住所が見つかりません、郵便番号をご確認ください。");
          } else if (results.length === 1) {
            // 単一住所のみヒット
            this.selectedAddress = results[0];
          } else {
            // 復数住所のヒット
            this.addressResults = results;
            this.isAddressSelectModalShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectAddressHandler(address) {
      this.selectedAddress = address;
      this.addressResults = [];
    },
  },
};
